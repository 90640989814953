import Error404 from "assets/404-error.svg";
import Image from "next/image";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import NavBarIcons from "src/components/CustomIcons/NavBarIcons";
import { useRouter } from "next/router";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Link from "next/link";
import Head from "next/head";

export default function NotFoundPage() {
    const router = useRouter();

    return (
        <>
            <Head>
                <title>404 Page not found - Parcel | PaymentOS for Contributor Economy</title>
            </Head>
            <div
                style={{
                    zIndex: 1400,
                    position: "absolute",
                    left: "0px",
                    top: "0px",
                    width: window.innerWidth,
                    background: "white",
                    height: window.innerHeight,
                }}
            >
                <div
                    style={{
                        height: "inherit",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Image src={Error404} width={327} height={250} />

                    <Typography fontWeight="700" fontSize={"18px"} style={{ color: "#25274F" }}>
                        Page not found!!
                    </Typography>
                    <div style={{ marginTop: 12, marginBottom: 35 }}>
                        <Typography fontWeight="400" fontSize={"12px"} style={{ color: "#636B81" }}>
                            The page are you looking for does not exist.
                        </Typography>
                    </div>
                    <Link href={"/dashboard"}>
                        <Button
                            variant="contained"
                            startIcon={<ArrowBackIosIcon style={{ fontSize: 14 }} />}
                        >
                            Go Back to Dashboard
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    );
}
